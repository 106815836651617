/*
 * @Description: 角色树
 * @Author: 前胡
 * @LastEditors: 肖槿
 * @Date: 2020-03-31 11:06:15
 * @LastEditTime: 2021-10-21 15:11:36
 */
export const RoleTree = [
  {
    title: '用户管理',
    key: 'user_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '注册申请',
        key: 'user_manage_reg_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '申请列表',
            key: 'user_manage_audit_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '申请审核',
            key: 'user_manage_audit_user',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '用户管理',
        key: 'user_manage_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '用户列表',
            key: 'user_manage_user_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '导出用户',
            key: 'user_manage_export_user',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '基本信息编辑',
            key: 'user_manage_modify_user_base_info',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '用户权限编辑',
            key: 'user_manage_modify_user_right_info',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '实名敏感信息',
            key: 'user_manage_read_user_identity_key_info',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '财务敏感信息',
            key: 'user_manage_read_user_fin_key_info',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '修改评级',
            key: 'user_manage_modify_level',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '解除绑定',
            key: 'user_manage_unbound_channel',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑推荐人',
            key: 'user_manage_modify_edit_recommend_user',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '用户详情',
        key: 'user_manage_user_detail_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '评级审核',
        key: 'user_manage_level_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '审核',
            key: 'user_manage_level_audit',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '评级白名单',
        key: 'user_manage_white_list_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '编辑',
            key: 'user_manage_modify_white_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
    ],
  },
  {
    title: '招商管理',
    key: 'merchant_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '商品初选池',
        key: 'merchant_manage_primary',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '商品复选池',
        key: 'merchant_manage_secondary',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '终审排期',
        key: 'merchant_manage_final',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '选品记录',
        key: 'merchant_manage_select_history',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
    ],
  },
  {
    title: '商品管理',
    key: 'goods_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '商品列表',
        key: 'goods_manage_list_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '商品列表',
            key: 'goods_manage_goods_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '新增商品',
            key: 'goods_manage_add_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑商品',
            key: 'goods_manage_modify_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '批量新增商品',
            key: 'goods_manage_batch_add_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '批量上架',
            key: 'goods_manage_batch_upshelf_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '批量下架',
            key: 'goods_manage_batch_downshelf_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '定时上下架',
            key: 'goods_manage_timing_upshelf_downshelf_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '批量导入结果下载',
            key: 'goods_manage_batch_import_result_download',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '批量定时上架',
            key: 'goods_manage_batch_timing_upshelf_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '批量定时下架',
            key: 'goods_manage_batch_timing_downshelf_goods',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '导出',
            key: 'goods_manage_export',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '商品预警',
        key: 'goods_manage_goods_warning_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '解除预警',
            key: 'goods_manage_goods_cancel_warning',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '精选联盟',
        key: 'goods_manage_alliance_list',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [],
      },
    ],
  },
  {
    title: '运营管理',
    key: 'operate_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '团长活动',
        key: 'campaign_manage',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '意见反馈',
        key: 'operate_manage_feedback_list_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '折一模块配置',
        key: 'operate_manage_custom_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '查看',
            key: 'operate_manage_custom_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑',
            key: 'operate_manage_modify_custom',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '专题管理页面',
        key: 'operate_manage_projects_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '专题列表',
            key: 'operate_manage_projects_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '新建专题',
            key: 'operate_manage_add_projects',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑专题',
            key: 'operate_manage_modify_projects',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '发布专题',
            key: 'operate_manage_release_projects',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '下线专题',
            key: 'operate_manage_offline_projects',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '专题报名管理',
        key: 'operate_manage_projects_apply_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          //     {
          //     "title": "报名列表",
          //     "key": "operate_manage_projects_list",
          //     "type": "api",
          //     "slots": {
          //         "icon": 'thunderbolt',
          //     },
          // },{
          //     "title": "报名详情",
          //     "key": "operate_manage_add_projects",
          //     "type": "api",
          //     "slots": {
          //         "icon": 'thunderbolt',
          //     },
          // }
        ],
      },
      {
        title: '专场管理页面',
        key: 'operate_manage_special_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '专场列表',
            key: 'operate_manage_special_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '创建专场',
            key: 'operate_manage_special_add',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑专场',
            key: 'operate_manage_special_modify',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
    ],
  },
  {
    title: '直播管理',
    key: 'samples_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '寄样申请',
        key: 'samples_manage_apply_list_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '寄样申请列表',
            key: 'samples_manage_apply_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '申请列表导出',
            key: 'samples_manage_export',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '导入物流信息',
            key: 'samples_manage_import',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '审核',
            key: 'samples_manage_audit',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '排期管理',
        key: 'samples_manage_live_group_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '编辑',
            key: 'samples_manage_live_group_modify',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '专场报名',
        key: 'samples_manage_special_apply_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '审核',
            key: 'samples_manage_special_apply_audit',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
    ],
  },
  {
    title: '提现管理',
    key: 'cash_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '实名认证',
        key: 'cash_manage_identity_list_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '实名认证列表',
            key: 'cash_manage_identity_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '审核',
            key: 'cash_manage_audit_identity',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '提现申请',
        key: 'cash_manage_apply_list_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '提现申请列表',
            key: 'cash_manage_apply_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '处理提现申请',
            key: 'cash_manage_modify_apply',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
    ],
  },
  // * 小店管理
  {
    title: '小店管理',
    key: 'small_shop_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '商家列表',
        key: 'business_list',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '查看商家列表页面',
            key: 'business_list_page',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '商家审核',
        key: 'business_audit',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '商家审核页面',
            key: 'business_audit_page',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '审核商家认证',
            key: 'business_audit_auth',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '查看审核记录',
            key: 'business_audit_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '资质审核',
        key: 'business_audit_qualifications',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '审核',
            key: 'business_audit_qualifications',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '商品管理',
        key: 'shop_goods_manage',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '商品管理页面',
            key: 'shop_goods_manage_page',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '商品管理审核',
            key: 'shop_goods_manage_audit',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '商品管理上下架',
            key: 'shop_goods_manage_upshelf_downshelf',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '商品管理编辑',
            key: 'shop_goods_manage_edit',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '商品管理查看',
            key: 'shop_goods_manage_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '订单管理',
        key: 'order_manage',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '订单管理页面',
            key: 'order_manage_page',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '订单管理导出',
            key: 'order_manage_export',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '账户管理',
        key: 'shop_account_manage',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '账户管理页面',
            key: 'shop_account_manage_page',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '账户管理查看',
            key: 'shop_account_manage_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '确认保证金',
            key: 'shop_account_manage_bond',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '提现待审核',
            key: 'shop_account_manage_wait_examine_tab',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '审核提现申请',
            key: 'shop_account_manage_examine_apply',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '提现待打款',
            key: 'shop_account_manage_wait_payment_tab',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '完成打款',
            key: 'shop_account_manage_complete_payment',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '保证金详情',
            key: 'shop_account_manage_bond_detail',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '结算管理',
        key: 'settle_accounts_manage',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '结算管理页面',
            key: 'settle_accounts_manage_page',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '结算管理查看明细',
            key: 'settle_accounts_manage_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '导入结算单',
            key: 'settle_accounts_manage_import_statement',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '小店管理',
        key: 'manage_small_shop_manage',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '小店管理页面',
            key: 'manage_small_shop_manage_page',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '新增小店',
            key: 'manage_small_shop_manage_add',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑小店',
            key: 'manage_small_shop_manage_edit',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      // * openmall
      /* {
        title: 'openmall商品管理-查看',
        key: 'openmall_goods_manage_list',
        type: 'api',
        slots: {
          icon: 'thunderbolt',
        },
      },
      {
        title: 'openmall商品管理-上架/编辑',
        key: 'openmall_goods_manage_edit',
        type: 'api',
        slots: {
          icon: 'thunderbolt',
        },
      },
      {
        title: 'openmall淘宝商品-查看',
        key: 'openmall_taobao_goods_manage_list',
        type: 'api',
        slots: {
          icon: 'thunderbolt',
        },
      },
      {
        title: 'openmall淘宝商品-上架',
        key: 'openmall_taobao_goods_manage_edit',
        type: 'api',
        slots: {
          icon: 'thunderbolt',
        },
      },
      {
        title: 'openmall订单管理-导出订单',
        key: 'openmall_order_export',
        type: 'api',
        slots: {
          icon: 'thunderbolt',
        },
      },*/
    ],
  },
  {
    title: '基础信息',
    key: 'basic_manage',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '规格管理页面',
        key: 'basic_specs_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '规格列表',
            key: 'basic_specs_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '添加规格',
            key: 'basic_specs_add',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑规格',
            key: 'basic_specs_modify',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '品牌管理页面',
        key: 'basic_brand_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '品牌管理列表',
            key: 'basic_brand_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '新增品牌',
            key: 'basic_brand_add',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑品牌',
            key: 'basic_brand_modify',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '小店店铺管理页面',
        key: 'basic_douyin_shop_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '店铺列表',
            key: 'basic_douyin_shop_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '新增店铺',
            key: 'basic_douyin_shop_add',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑店铺',
            key: 'basic_douyin_shop_modify',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '供应商管理页面',
        key: 'basic_supplier_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '新增供应商',
            key: 'basic_supplier_add',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑供应商',
            key: 'basic_supplier_modify',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
    ],
  },
  {
    title: '数据统计',
    key: 'data_stat',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '搜索统计页面',
        key: 'data_stat_search_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '服务费明细查看',
        key: 'alliance_orders_manage',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '服务费明细导出',
        key: 'alliance_orders_manage_export',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      /*   {
        title: '查看渠道汇总',
        key: 'alliance_orders_manage_recommend_summary',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '渠道明细导出',
        key: 'alliance_orders_manage_recommend_summary_export',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },*/
      {
        title: '查看招商汇总',
        key: 'alliance_orders_manage_merchant_summary',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
      {
        title: '招商明细导出',
        key: 'alliance_orders_manage_merchant_summary_export',
        type: 'path',
        slots: {
          icon: 'profile',
        },
      },
    ],
  },
  {
    title: '控制面板',
    key: 'control_panel',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '权益设置',
        key: 'control_panel_welfare_setting',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '编辑权益',
            key: 'control_panel_modify_welfare_setting',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
    ],
  },
  {
    title: '设置',
    key: 'setting',
    type: 'dir',
    slots: {
      icon: 'layout',
    },
    children: [
      {
        title: '账号管理',
        key: 'account_manage_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '账号管理列表',
            key: 'account_manage_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '添加账号',
            key: 'account_manage_add_ac',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑账号',
            key: 'account_manage_modify_ac',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '重置密码',
            key: 'account_manage_reset_password',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '删除账号',
            key: 'account_manage_del_ac',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
      {
        title: '角色管理',
        key: 'role_manage_page',
        type: 'path',
        slots: {
          icon: 'profile',
        },
        children: [
          {
            title: '角色管理列表',
            key: 'role_manage_list',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '添加角色',
            key: 'role_manage_add_role',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '编辑角色',
            key: 'role_manage_modify_role',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
          {
            title: '删除角色',
            key: 'role_manage_del_role',
            type: 'api',
            slots: {
              icon: 'thunderbolt',
            },
          },
        ],
      },
    ],
  },
];
