/*
 * @Description: 路由配置文件
 * @Date: 2019-12-21 17:21:24
 * @LastEditTime: 2021-10-25 10:01:31
 * @LastEditors: 肖槿
 */
import VueRouter from 'vue-router';
import Vue from 'vue';
import store from '../store/index';
import shopRouters from './shop';

Vue.use(VueRouter);

// 无权限的路由
export const normalRouter = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('../pages/loading'),
    meta: {
      roles: [],
    },
  },
  {
    path: '/h5maker',
    name: 'h5maker',
    component: () => import('../pages/admin/operate/h5maker'),
    meta: {
      roles: [],
    },
  },
  {
    path: '/homeMaker',
    name: 'homeMaker',
    component: () => import('../pages/admin/operate/home-maker'),
    meta: {
      roles: [],
    },
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../pages/login'),
    meta: {
      roles: [],
    },
  },
  {
    path: '/user/user-agreement',
    name: '用户协议',
    component: () => import('../pages/user/user-agreement'),
    meta: {
      roles: [],
    },
  },
  {
    path: '/user/settlement-agreement',
    name: '结算协议',
    component: () => import('../pages/user/settlement-agreement'),
    meta: {
      roles: [],
    },
  },
  {
    path: '/user/privacy-policy',
    name: '隐私政策',
    component: () => import('../pages/user/privacy-policy'),
    meta: {
      roles: [],
    },
  },
];

// 有权限的路由
export const permissionsRouter = [
  {
    path: '/admin',
    component: () => import('../pages/admin/admin'),
    meta: {
      roles: ['x'],
    },
    children: [
      {
        path: 'user',
        name: '用户管理',
        component: () => import('../pages/admin/user/index'),
        meta: {
          title: '用户管理',
          icon: 'user',
          roles: ['user_manage'],
        },
        children: [
          {
            path: 'audit',
            name: '注册申请',
            component: () => import('../pages/admin/user/audit'),
            meta: {
              title: '注册申请',
              roles: ['user_manage_reg_page'],
            },
          },
          {
            path: 'list',
            name: '用户列表',
            component: () => import('../pages/admin/user/list'),
            meta: {
              title: '用户列表',
              roles: ['user_manage_page'],
            },
          },
          {
            path: 'rate_audit',
            name: '评级审核',
            component: () => import('../pages/admin/user/level-audit'),
            meta: {
              title: '评级审核',
              roles: ['user_manage_level_page'],
            },
          },
          {
            path: 'rate_whitelist',
            name: '评级白名单',
            component: () => import('../pages/admin/user/rate-white-list'),
            meta: {
              title: '评级白名单',
              roles: ['user_manage_white_list_page'],
            },
          },
          {
            path: 'detail/:id/',
            name: '用户详情',
            component: () => import('../pages/admin/user/list'),
            hidden: true,
            meta: {
              title: '用户详情',
              roles: ['user_manage_user_detail_page'],
            },
          },
        ],
      },
      {
        path: 'goods',
        name: '商品管理',
        component: () => import('../pages/admin/goods/index'),
        // redirect: {
        //   path: 'goods/list'
        // },
        meta: {
          title: '商品管理',
          icon: 'shopping-cart',
          roles: ['goods_manage'],
        },
        children: [
          {
            path: 'list',
            name: '商品列表',
            component: () => import('../pages/admin/goods/list'),
            meta: {
              title: '商品列表',
              roles: ['goods_manage_list_page'],
            },
          },
          {
            path: 'warning',
            name: '商品预警',
            component: () => import('../pages/admin/goods/warning'),
            meta: {
              title: '商品预警',
              roles: ['goods_manage_goods_warning_page'],
            },
          },
          {
            path: 'featured',
            name: '精选联盟',
            component: () => import('../pages/admin/goods/featured/featured.vue'),
            meta: {
              title: '精选联盟',
              roles: ['goods_manage_alliance_list'],
            },
          },
          {
            path: 'featured/:id/',
            name: '商品查看',
            component: () => import('../pages/admin/goods/featured/detail.vue'),
            hidden: true,
            meta: {
              title: '商品查看',
              roles: ['goods_manage_alliance_list'],
            },
          },
        ],
      },
      {
        path: 'merchant',
        name: '招商管理',
        component: () => import('../pages/admin/merchant/index'),
        redirect: {
          path: 'sample/apply',
        },
        meta: {
          title: '招商管理',
          icon: 'gift',
          roles: ['merchant_manage'],
        },
        children: [
          {
            path: 'productPrimary',
            name: '商品初选池',
            component: () => import('../pages/admin/merchant/primary-list.vue'),
            meta: {
              title: '商品初选池',
              roles: ['merchant_manage_primary'],
            },
          },
          {
            path: 'productChoose',
            name: '商品复选池',
            component: () => import('../pages/admin/merchant/choose-list.vue'),
            meta: {
              title: '商品复选池',
              roles: ['merchant_manage_secondary'],
            },
          },
          {
            path: 'productFinal',
            name: '终审排期',
            component: () => import('../pages/admin/merchant/final-list.vue'),
            meta: {
              title: '终审排期',
              roles: ['merchant_manage_final'],
            },
          },
          {
            path: 'selectionRecord',
            name: '选品记录',
            component: () => import('../pages/admin/merchant/selection-record.vue'),
            meta: {
              title: '选品记录',
              roles: ['merchant_manage_select_history'],
            },
          },
        ],
      },
      {
        path: 'sample',
        name: '直播管理',
        component: () => import('../pages/admin/sample/index'),
        redirect: {
          path: 'sample/apply',
        },
        meta: {
          title: '直播管理',
          icon: 'global',
          roles: ['samples_manage'],
        },
        children: [
          {
            path: 'apply',
            name: '寄样申请',
            component: () => import('../pages/admin/sample/apply'),
            meta: {
              title: '寄样申请',
              roles: ['samples_manage_apply_list_page'],
            },
          },
          /* {
            path: 'schedule',
            name: '直播排期',
            component: () => import('../pages/admin/sample/schedule'),
            meta: {
              title: '直播排期',
              roles: ['samples_manage_live_group_page'],
            },
          },*/
          {
            path: 'schedulingManagement',
            name: '排期管理',
            component: () => import('../pages/admin/sample/scheduling-management.vue'),
            meta: {
              title: '排期管理',
              roles: ['samples_manage_live_group_page'],
            },
          },
          {
            path: 'schedulingManagement/goods',
            name: '已排期商品',
            hidden: true,
            component: () => import('../pages/admin/sample/scheduled-goods.vue'),
            meta: {
              customCrumbs: ['直播管理', '排期管理', '已排期商品'],
              title: '已排期商品',
              roles: ['samples_manage_live_group_page'],
            },
          },
          {
            path: 'special',
            name: '专场报名',
            component: () => import('../pages/admin/sample/special'),
            meta: {
              title: '专场报名',
              roles: ['samples_manage_special_apply_page'],
            },
          },
        ],
      },
      {
        path: 'operate',
        name: '运营管理',
        component: () => import('../pages/admin/operate/index'),
        meta: {
          title: '运营管理',
          icon: 'control',
          roles: ['operate_manage'],
        },
        children: [
          {
            path: 'head_activity',
            name: '团长活动',
            component: () => import('../pages/admin/head_activity/index'),
            meta: {
              title: '团长活动',
              roles: ['campaign_manage'],
            },
          },
          {
            path: 'head_activity/goods',
            name: '商品详情',
            hidden: true,
            component: () => import('../pages/admin/head_activity/activity_goods'),
            meta: {
              customCrumbs: ['运营管理', '团长活动', '商品详情'],
              title: '商品详情',
              roles: ['campaign_manage'],
            },
          },
          {
            path: 'homeConfig',
            name: '折一模块配置',
            component: () => import('../pages/admin/operate/homeconfig'),
            meta: {
              title: '折一模块配置',
              roles: ['operate_manage_custom_page'],
            },
          },
          {
            path: 'topic',
            name: '专题管理',
            component: () => import('../pages/admin/operate/topic'),
            meta: {
              title: '专题管理',
              roles: ['operate_manage_projects_page'],
            },
          },
          {
            path: 'topicRegManage',
            name: '专题报名管理',
            component: () => import('../pages/admin/operate/topic-reg-manage'),
            meta: {
              title: '专题报名管理',
              roles: ['operate_manage_projects_apply_page'], // TODO
            },
          },
          {
            path: 'topicRegManage/:id/',
            name: '专题报名管理 / 报名详情',
            component: () => import('../pages/admin/operate/topic-reg-detail'),
            hidden: true,
            meta: {
              title: '专题报名详情',
              roles: ['operate_manage_projects_apply_page'], // TODO
            },
          },
          {
            path: 'specialManage',
            name: '专场管理',
            component: () => import('../pages/admin/operate/special'),
            meta: {
              title: '专场管理',
              roles: ['operate_manage_special_page'],
            },
          },
          {
            path: 'feedback',
            name: '意见反馈',
            component: () => import('../pages/admin/operate/feedback'),
            meta: {
              title: '意见反馈',
              roles: ['operate_manage_feedback_list_page'],
            },
          },
        ],
      },
      {
        path: 'withdraw',
        name: '提现管理',
        component: () => import('../pages/admin/withdraw/index'),
        // redirect: {
        //   path: 'withdraw/realname'
        // },
        meta: {
          title: '提现管理',
          icon: 'money-collect',
          roles: ['cash_manage'],
        },
        children: [
          {
            path: 'realname',
            name: '实名认证',
            component: () => import('../pages/admin/withdraw/realname-approval'),
            meta: {
              title: '实名认证',
              roles: ['cash_manage_identity_list_page'],
            },
          },
          {
            path: 'withdraw',
            name: '提现申请',
            component: () => import('../pages/admin/withdraw/withdraw-apply'),
            meta: {
              title: '提现申请',
              roles: ['cash_manage_apply_list_page'],
            },
          },
        ],
      },
      {
        path: 'baseinfo',
        name: '基础信息',
        component: () => import('../pages/admin/baseinfo/index'),
        meta: {
          title: '基础信息',
          icon: 'flag',
          roles: ['basic_manage'],
        },
        children: [
          {
            path: 'spec',
            name: '规格管理',
            component: () => import('../pages/admin/baseinfo/spec'),
            meta: {
              title: '规格管理',
              roles: ['basic_specs_page'],
            },
          },
          {
            path: 'brand',
            name: '品牌管理',
            component: () => import('../pages/admin/baseinfo/brand'),
            meta: {
              title: '品牌管理',
              roles: ['basic_brand_page'],
            },
          },
          {
            path: 'douyinStore',
            name: '小店店铺管理',
            component: () => import('../pages/admin/baseinfo/douyin-store'),
            meta: {
              title: '小店店铺管理',
              roles: ['basic_douyin_shop_page'],
            },
          },
          {
            path: 'supplier',
            name: '供应商管理',
            component: () => import('../pages/admin/baseinfo/supplier'),
            meta: {
              title: '供应商管理',
              roles: ['basic_supplier_page'],
            },
          },
        ],
      },
      ...shopRouters,
      {
        path: 'statistical',
        name: '数据统计',
        component: () => import('../pages/admin/statistical/index'),
        meta: {
          title: '数据统计',
          icon: 'trophy',
          roles: ['data_stat'],
        },
        children: [
          {
            path: 'search',
            name: '搜索统计',
            component: () => import('../pages/admin/statistical/search-statistical'),
            meta: {
              title: '搜索统计',
              roles: ['data_stat_search_page'],
            },
          },
          {
            path: 'serverIncome',
            name: '服务费收入',
            component: () => import('../pages/admin/statistical/server-income'),
            meta: {
              title: '服务费收入',
              roles: ['alliance_orders_manage'], //data_stat_server_income
            },
          },
        ],
      },
      {
        path: 'dashboard',
        name: '控制面板',
        component: () => import('../pages/admin/dashboard/index'),
        meta: {
          title: '控制面板',
          icon: 'trophy',
          roles: ['control_panel'],
        },
        children: [
          {
            path: 'equity',
            name: '权益设置',
            component: () => import('../pages/admin/dashboard/equity'),
            meta: {
              title: '权益设置',
              roles: ['control_panel_welfare_setting'],
            },
          },
        ],
      },
      {
        path: 'setting',
        name: '设置',
        component: () => import('../pages/admin/setting/index'),
        // redirect: {
        //   path: 'withdraw/realname'
        // },
        meta: {
          title: '设置',
          icon: 'setting',
          roles: ['setting', 'account_manage_page', 'role_manage_page'],
        },
        children: [
          {
            path: 'account',
            name: '账号管理',
            component: () => import('../pages/admin/setting/account'),
            meta: {
              title: '账号管理',
              roles: ['account_manage_page'],
            },
          },
          {
            path: 'role',
            name: '角色管理',
            component: () => import('../pages/admin/setting/role'),
            meta: {
              title: '角色管理',
              roles: ['role_manage_page'],
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes: normalRouter,
});

let getRouter;

function routerGo(to, next) {
  getRouter.push({
    path: '*',
    component: resolve => require(['@/pages/404.vue'], resolve),
    name: '404',
  });
  router.addRoutes(getRouter); //动态添加路由
  const finalRoute = router.options.routes.concat(getRouter);
  store.commit('setRoutes', finalRoute);
  next({
    ...to,
    replace: true,
  });
}

// 根据权限 筛选路由
function filterAsyncRouter(asyncRouterMap, roles) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (roles.includes(route.meta.roles[0])) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}

router.beforeEach(async (to, from, next) => {
  if (!getRouter) {
    // 没有获取权限表
    if (store.state.user && store.state.user.userinfo) {
      // 已登陆
      // const {
      //   res
      // } = await getUserRight()
      // let roles = res.data
      // roles.push('x')
      // const rights = getAllRights(roles)
      await store.dispatch('getRights');
      getRouter = filterAsyncRouter(permissionsRouter, store.state.user.rights);
      routerGo(to, next);
    } else {
      if (!to.name) {
        next({ name: '登录' });
      }
      next();
    }
  } else {
    if (to.path === '/login') {
      // this.$Message.error('您已登录,如需切换用户请退出重新登录')
      next({ name: 'loading' });
    }
    next();
  }
});

export default router;
