/*
 * @Description: 配置文件
 * @Date: 2019-12-23 14:40:05
 * @LastEditTime: 2020-07-24 19:21:19
 * @LastEditors: 前胡
 */
console.log('process.env.targetEnv', process.env.targetEnv);
const api = {
  development: 'http://admin-test.zheyi360.com',
  test: 'http://admin-test.zheyi360.com',
  pre: 'http://admin-test.zheyi360.com',
  production: 'https://admin.zheyi360.com',
};
const oss = {
  // development: 'http://192.168.2.49:8888',
  // development: 'http://172.16.4.16:8888',
  // development: 'http://192.168.2.37:9000', // qf
  development: 'http://static.zheyi360.com/test',
  test: 'http://static.zheyi360.com/test',
  pre: 'http://static.zheyi360.com/pre',
  production: 'https://static.zheyi360.com/prod',
};
export const baseURL = api[process.env.targetEnv];
export const ossURL = oss[process.env.targetEnv];
